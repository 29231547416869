import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";import { themeChange } from "theme-change";
// import { useAuth } from './app/auth';
import initializeApp from "./app/init";
import { useAuth } from "./app/auth";

// Importing pages
// const Layout = lazy(() => import('./containers/Layout'))
// const Login = lazy(() => import('./pages/Login'))
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
// const Register = lazy(() => import('./pages/Register'))
// const Documentation = lazy(() => import('./pages/Documentation'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import("./pages/LoginUser"));
// const Dashboard = lazy(() => import("./pages/protected/PageContent"));

// Initializing different libraries
initializeApp();

function App() {
  const auth = useAuth();
  useEffect(() => {
    console.log(auth.user)
    // 👆 daisy UI themes initialization
    themeChange(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/" element={<Dashboard />} /> */}
          {/* <Route path="*" element={<Navigate to={auth?.user ? "/" : "/login"} replace />}/> */}
          {/* <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} /> */}

          {/* Place new routes over this */}
          {/* <Route path="/app/*" element={<Layout />} /> */}

          {/* <Route path="*" element={<Navigate to={auth?.user ? "/app/welcome" : "/login"} replace />}/> */}
          <Route path="*" element={<Home/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
